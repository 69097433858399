<template>
  <div class="comment">
    <div>
      <label class="content" v-if="!isEditing">{{ comment.content }}</label>
      <TextBox v-if="isEditing" :placeholder="$t('Profile.EditCommentPlaceholder')" :value="contentEdit" @input="contentEdit = $event"></TextBox>
    </div>
    <div>
      <label class="content">
        {{ new Date(comment.createdAt).toLocaleString() }}
        {{ comment.edited ? $t('Profile.Edited') : '' }}
      </label>
    </div>
    <div class="comment-action-box">
      <router-link class="content link" :to="`/profile?id=${comment.authorId}`">{{ comment.authorPreName }} {{ comment.authorLastName }} </router-link>
      <label v-if="$store.state.auth.id === comment.authorId">
        <span class="content dot" v-if="!isEditing"> • </span>
        <span class="content link" v-if="!isEditing" @click="$emit('remove')">{{ $t('Profile.Remove') }}</span>
        <span class="content dot" v-if="isEditing"> • </span>
        <span class="content link" v-if="isEditing" @click="cancelEditing()">Cancel</span>
        <span class="content dot"> • </span>
        <span class="content link" @click="editComment()">{{ isEditing ? $t('Profile.Done') : $t('Profile.Edit') }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import TextBox from '../components/TextBox.vue'

export default {
  props: {
    comment: {}
  },
  components: {
    TextBox
  },
  data() {
    return {
      isEditing: false,
      contentEdit: ''
    }
  },
  methods: {
    editComment() {
      if (this.isEditing) {
        this.doneEditing()
        return
      }
      this.contentEdit = this.comment.content
      this.isEditing = true
    },
    async doneEditing() {
      this.comment.content = this.contentEdit
      const token = await localStorage.getItem('token')
      this.axios
        .post(
          "comment/edit",
          {
            id: this.comment._id,
            content: this.comment.content,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.isEditing = false
        })
    },
    cancelEditing() {
      this.isEditing = false
      this.contentEdit = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/colors';

.comment {
  padding: 1em;
  background-color: colors.$cards;
}

.dot {
  user-select: none;
  cursor: default;
}
</style>
