<template>
  <div class="page">
    <div class="header-container">
      <div class="back-item">
        <router-link class="content link" to="/">← {{ $t('General.Back') }}</router-link>
      </div>
      <div class="action-item" v-if="$store.state.auth.id === id">
        <router-link class="content link" to="/customize">{{ $t('Profile.Edit') }}</router-link>
      </div>
    </div>
    <div class="header-space"></div>
    <div class="profile-header">
      <div class="profile-picture-view">
        <img :src="user.imageUrl || fallbackImageUrl(user.preName, user.lastName)" alt="profile picture" />    
      </div>
      <div>
        <div>
          <label class="title">{{ user.preName }} {{ user.lastName }}</label>
        </div>
        <div>
          <label class="heading">{{ user.status }}</label>
        </div>
      </div>
    </div>
    <div class="comment-section">
      <div class="write-comment-line">
        <div>
          <TextBox :placeholder="$t('Profile.CommentPlaceholder')" :value="comment" @input="comment = $event"></TextBox>
        </div>
        <div @click="saveComment()">
          <Button :text="$t('Profile.Publish')"></Button>
        </div>
      </div>
      <div class="comment-list">
        <div v-for="comment of comments" :key="comment._id">
          <Comment :comment="comment" @remove="removeComment(comment)"></Comment>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextBox from '../components/TextBox.vue'
import Button from '../components/Button.vue'
import Comment from '../components/Comment.vue'

export default {
  name: "Profile",
  components: {
    TextBox, Button, Comment
  },
  data() {
    return {
      user: {},
      comment: "",
      comments: []
    };
  },
  mounted() {
    this.getUser();
    this.fetchComments()
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {
    id() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getUser();
      this.fetchComments()
    }
  },
  methods: {
    async getUser() {
      const token = await localStorage.getItem("token");
      this.axios
        .get(`user/get?userId=${this.$route.query.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.user = r.data;
        })
    },
    async saveComment() {
      const token = await localStorage.getItem("token");
      this.axios
        .post(
          "comment/create",
          {
            userId: this.$route.query.id,
            content: this.comment,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.comment = ''
          this.fetchComments()
        })
    },
    async removeComment(comment) {
      const token = await localStorage.getItem("token");
      this.axios
        .delete(
          `comment/remove?id=${comment._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          this.fetchComments()
        })
    },
    async fetchComments() {
      const token = await localStorage.getItem("token");
      this.axios
        .get(
          `comment/get?userId=${this.$route.query.id}&skip=0&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r) => {
          this.comments = r.data
        })
    },
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/colors';
@use '@/styles/config';

.profile-header {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.profile-picture-view {
  width: 10em;
  height: 10em;
  background-color: colors.$cards;
  
  & img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}

.comment-section {
  padding-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.write-comment-line {
  display: flex;
  flex-direction: row;
  gap: 1em;

  & div:first-child {
    width: 100%;
  }
}

.comment-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.comment {
  padding: 1em;
  background-color: colors.$cards;
}

.dot {
  user-select: none;
  cursor: default;
}
</style>
